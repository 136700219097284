import React from 'react';
import { MarkDown } from 'components';
import SethuProject from 'assets/svg/sethu.svg';
import Spudremoval from 'assets/svg/marine.svg';
import SamplingAround from 'assets/svg/sampling.svg';
import Environmental from 'assets/svg/environment.svg';
import { CardSectionProps, CardSectionContentProps } from 'types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import ReadMoreArrow from 'assets/svg/arrowright.svg';

const CardSection = ({
  sectionTitle,
  cardSection,
  theme,
  ctaLink,
  ctaText,
  summary,
  backgroundImage,
}: CardSectionProps) => {
  const backgroundImageObj = backgroundImage?.localFile
    ? getImage(backgroundImage.localFile.childImageSharp?.gatsbyImageData)
    : null;

  return (
    <div className={`card-section ${theme ? `theme-${theme}` : ''}`}>
      {backgroundImageObj && (
        <GatsbyImage
          image={backgroundImageObj}
          alt=" "
          className="section-background-image"
        />
      )}
      <div className="container">
        <div className="card-section-content">
          {sectionTitle?.title && (
            <h2 className="card-section-content-title">
              <MarkDown context="heading">{sectionTitle?.title}</MarkDown>
            </h2>
          )}
          {sectionTitle?.description && (
            <MarkDown className="card-section-content-description">
              {sectionTitle?.description.data.description}
            </MarkDown>
          )}
        </div>
        <div className="card-wrapper">
          {cardSection.length > 0 &&
            cardSection.map((item: CardSectionContentProps) => {
              const { title, description, icon } = item;
              let Icon;
              switch (icon) {
                case 'SethuSamuthiramProject':
                  Icon = <SethuProject />;
                  break;
                case 'MarineSalvageOperations':
                  Icon = <Spudremoval />;
                  break;
                case 'EnvironmentAssessmentStudies':
                  Icon = <SamplingAround />;
                  break;
                case 'SamplingAroundPalkBay':
                  Icon = <Environmental />;
                  break;
                default:
                  break;
              }
              return (
                <div className="card-wrapper-content" key={title}>
                  {Icon && (
                    <div className="card-wrapper-content-svg">{Icon}</div>
                  )}
                  {title && (
                    <h4 className="card-wrapper-content-title">{title}</h4>
                  )}

                  {description && (
                    <MarkDown className="card-wrapper-content-description">
                      {description.data.description}
                    </MarkDown>
                  )}
                </div>
              );
            })}
        </div>
        {summary && (
          <div className="card-wrapper-summary">
            <MarkDown>{summary}</MarkDown>
          </div>
        )}
        {ctaText && (
          <div className="cta-btn">
            <Link to={ctaLink}>
              <span>{ctaText}</span>
              <ReadMoreArrow />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export { CardSection };
