/* eslint-disable no-lone-blocks */
import React from 'react';
import { MarkDown } from 'components';
import { ProjectListProps, ProjectSectionDataProps, SurveysProps } from 'types';

export const ProjectSection = ({ projectList }: ProjectListProps) => {
  return (
    <div className="container project-section-wrapper">
      {projectList &&
        projectList.length > 0 &&
        projectList.map((tab: any) => {
          {
            return tab.projects.map((item: ProjectSectionDataProps) => {
              const {
                title,
                tagline,
                heading,
                description,
                duration,
                surveyTitle,
                surveys,
              } = item;

              return (
                <div className="project-section">
                  <div className="project-section-detail">
                    {title && (
                      <h2 className="project-section-title">{title}</h2>
                    )}
                    {tagline && (
                      <p className="project-section-tagline">{tagline}</p>
                    )}
                    {heading && (
                      <h4 className="project-section-heading">{heading}</h4>
                    )}
                    {duration && (
                      <p className="project-section-duration">{duration}</p>
                    )}

                    <div className="project-section-survey">
                      <p>{surveyTitle}</p>
                      <div className="project-section-survey-list">
                        {surveys &&
                          surveys.map((data: SurveysProps) => {
                            return (
                              <ul>
                                <li>{data.title}</li>
                              </ul>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  {description && (
                    <div className="project-section-desc">
                      <MarkDown>{description?.data}</MarkDown>
                    </div>
                  )}
                </div>
              );
            });
          }
        })}
    </div>
  );
};
