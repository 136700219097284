import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import RightArrow from 'assets/svg/next.svg';
import LeftArrow from 'assets/svg/previous.svg';
import { GalleryItemProps, GalleryModalProp } from 'types';

const GalleryModal = ({
  isModal,
  closesModal,
  gallery,
  selectedImage,
  setSelectedImage,
}: GalleryModalProp) => {
  useEffect(() => {
    if (isModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isModal]);
  const filtered = gallery.filter((item: any, index: number) => {
    return index === selectedImage;
  });
  const handlePrevRequest = () => {
    setSelectedImage((selectedImage - 1 + gallery.length) % gallery.length);
  };

  const handleNextRequest = () => {
    setSelectedImage((selectedImage + 1 + gallery.length) % gallery.length);
  };
  useEffect(() => {
    const activeImageObj = gallery[selectedImage];
    if (activeImageObj) {
      const slug = `${activeImageObj.title
        .replace(/[^a-zA-Z\d:]/g, '-')
        .toLowerCase()}-${activeImageObj.strapi_id}`;
      window.history.pushState('', '', `/gallery/${slug}`);
    } else {
      window.history.pushState('', '', `/gallery`);
    }
  }, [selectedImage]);
  return (
    <ReactModal
      isOpen={isModal}
      onRequestClose={closesModal}
      ariaHideApp={false}
    >
      <button type="button" className="modal-close" onClick={closesModal}>
        <span className="close">&#x2715;</span>
      </button>
      <button
        className="modal-btn prev"
        type="button"
        onClick={handlePrevRequest}
      >
        <LeftArrow />
      </button>
      {filtered.map((item: GalleryItemProps) => {
        const { title, image } = item;
        const imageItem = image?.localFile
          ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
          : null;
        return (
          <div className="modal-img" key={title}>
            <GatsbyImage image={imageItem} alt={title} />
          </div>
        );
      })}
      <button
        className="modal-btn next"
        type="button"
        onClick={handleNextRequest}
      >
        <RightArrow />
      </button>
    </ReactModal>
  );
};

export { GalleryModal };
