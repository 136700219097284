import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Moment from 'react-moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink } from 'components';
import { ArticleSliderProps, AllArticleProps } from 'types';

const ArticleSlider = ({ articles, theme }: ArticleSliderProps) => {
  return (
    <div className={`blogs-wrapper ${theme ? `theme-${theme}` : ''}`}>
      <div className="blogs-wrapper-content">
        <Swiper
          slidesPerView={1.2}
          breakpoints={{
            992: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
          }}
        >
          {articles.map((item: AllArticleProps) => {
            const { title, date, image, slug } = item.node;
            const Image: any =
              image && image && image.localFile
                ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                : null;
            return (
              <SwiperSlide key={title}>
                <div className="blogs-wrapper-content-wrapper">
                  <CTALink ctaLink={`/blogs/${slug}`}>
                    {Image && (
                      <GatsbyImage image={Image} alt={`${image.name} || '' `} />
                    )}
                    {title && (
                      <p className="blogs-wrapper-content-title">{title}</p>
                    )}
                  </CTALink>
                  {date && (
                    <p className="blogs-wrapper-content-date">
                      <Moment format="D MMM, YYYY">{date}</Moment>
                    </p>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export { ArticleSlider };
