import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ImagePropTypes } from 'types';

export interface metaDescriptionProp {
  data: { metaDescription: String };
}

interface SeoProps {
  description?: string;
  lang?: string;
  title: string;
  page?: string;
  author?: string;
  image?: ImagePropTypes;
  data?: string;
}

const Seo: FC<SeoProps> = ({
  description = '',
  lang = 'en',
  title,
  author = '',
  image,
}: SeoProps) => {
  const { pathname } = useLocation();
  return (
    <StaticQuery
      query={seoDetailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata: {
              defaultTitle,
              siteUrl,
              isDevEnvironment,
              defaultAuthor,
            },
          },
          strapiGlobal: {
            defaultSeo: {
              metaTitle: defaultMetaTitle,
              metaDescription: defaultMetaDescription,
              shareImage: shareImageURL,
            },
          },
        } = data;

        const seoTitle = title || defaultMetaTitle;
        const seoDescription =
          description?.data?.metaDescription ||
          defaultMetaDescription?.data?.metaDescription;
        const metaUrl = `${siteUrl}${pathname}`;
        const metaImage =
          image ||
          (shareImageURL?.localFile?.publicURL &&
            `${siteUrl}${shareImageURL?.localFile?.publicURL}`);
        const canonical = pathname ? `${siteUrl}${pathname}` : null;
        const isDev = isDevEnvironment;
        const Author = author || defaultAuthor;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seoTitle}
            titleTemplate={`%s | ${defaultTitle}`}
            link={
              canonical
                ? [
                    {
                      rel: 'canonical',
                      href: canonical,
                    },
                  ]
                : []
            }
            meta={[
              {
                name: 'description',
                content: seoDescription,
              },
              {
                name: 'image',
                content: metaImage,
              },
              {
                name: 'og:image:width',
                content: 200,
              },
              {
                name: 'og:image:height',
                content: 150,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: 'og:description',
                content: seoDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: Author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: seoDescription,
              },
            ]
              .concat(
                metaImage
                  ? [
                      {
                        property: `og:image`,
                        content: metaImage,
                      },
                      {
                        property: `og:image:alt`,
                        content: seoTitle,
                      },
                    ]
                  : []
              )
              .concat(
                isDev
                  ? [
                      {
                        property: `robots`,
                        content: `noindex`,
                      },
                    ]
                  : []
              )}
          />
        );
      }}
    />
  );
};

export { Seo };

Seo.defaultProps = {
  description: ' ',
  lang: ' ',
  page: ' ',
  author: ' ',
};
const seoDetailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultAuthor: author
        defaultImage: image
      }
    }
    strapiGlobal {
      defaultSeo {
        metaTitle
        metaDescription {
          data {
            metaDescription
          }
        }
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
