import React from 'react';
import PhoneIcon from 'assets/svg/phoneicon.svg';
import { ContactPeopleProps, ContactPeopleDetailProps } from 'types';
import { ContactForm } from 'components';
import ReactMarkdown from 'react-markdown';

export const ContactPeople = ({ contactPeople }: ContactPeopleDetailProps) => {
  return (
    <div className="container">
      <div className="contact">
        <div className="section-heading">
          <h2 className="section-title">
            <ReactMarkdown>Contact **us**</ReactMarkdown>
          </h2>

          <div className="section-content">
            <ReactMarkdown>
              Give us a call or drop an email, We endeavor to answer within 24
              hours
            </ReactMarkdown>
          </div>
        </div>
        <div className="contact_wrapper">
          <ContactForm />
          <div className="contact-people">
            {contactPeople?.length > 0 &&
              contactPeople.map((item: ContactPeopleProps) => {
                const { name, designation, phonenumber, phonenumber1 } = item;
                return (
                  <div className="contact-people-section">
                    <div className="contact-people-detail">
                      {name && (
                        <ReactMarkdown className="contact-people-name">
                          {name}
                        </ReactMarkdown>
                      )}
                      {designation && (
                        <ReactMarkdown className="contact-people-designation">
                          {designation}
                        </ReactMarkdown>
                      )}
                      {phonenumber && phonenumber1 && (
                        <div className="contact-people-phn_num">
                          <div className="phone-icon">
                            <PhoneIcon />
                          </div>
                          <div className="contact-number">
                            <p>{phonenumber}</p>
                            <p>{phonenumber1}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
