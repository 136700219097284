import React from 'react';
import { Link } from 'gatsby';
import { MarkDown } from 'components';
import ReadMoreArrow from 'assets/svg/arrowright.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { TextImageProps, TextImageSectionProps } from 'types';

const TextImage = ({ textImage }: TextImageProps) => {
  return (
    <section className="two-column-list">
      {textImage?.length > 0 &&
        textImage.map((item: TextImageSectionProps) => {
          const {
            title,
            description,
            ctaText,
            ctaLink,
            image,
            theme,
            backgroundImage,
          } = item;
          const imageItem = image?.localFile
            ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
            : null;
          const backgroundImageObj = backgroundImage?.localFile
            ? getImage(
                backgroundImage.localFile.childImageSharp?.gatsbyImageData
              )
            : null;
          return (
            <div
              className={`two-column-list-item ${
                theme ? `theme-${theme}` : ''
              }`}
              key={title}
            >
              {backgroundImageObj && (
                <GatsbyImage
                  image={backgroundImageObj}
                  alt=" "
                  className="section-background-image"
                />
              )}
              <div className="container">
                <div className="section-wrapper">
                  {title && (
                    <h3 className="section-wrapper-content-title-mbl">
                      <MarkDown context="heading">{title}</MarkDown>
                    </h3>
                  )}
                  {imageItem && (
                    <div className="section-wrapper-image">
                      <GatsbyImage image={imageItem} alt="" />
                    </div>
                  )}
                  <div className="section-wrapper-content">
                    {title && (
                      <h3 className="section-wrapper-content-title">
                        <MarkDown context="heading">{title}</MarkDown>
                      </h3>
                    )}
                    {description && (
                      <MarkDown className="section-wrapper-content-description">
                        {description.data.description}
                      </MarkDown>
                    )}

                    {ctaText && (
                      <div className="cta-btn">
                        <Link to={ctaLink}>
                          <span>{ctaText}</span>
                          <ReadMoreArrow />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </section>
  );
};

export { TextImage };
