/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import { useStaticQuery, graphql } from 'gatsby';
import Loader from 'assets/svg/loader.svg';
import data from './form.json';

const ContactForm = () => {
  const { details, submit } = data;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm();
  const [loading, isloading] = React.useState(false);
  const [formValidation, changeFormValidation] = React.useState('');

  // eslint-disable-next-line @typescript-eslint/no-use-before-define

  const formQueryData: any = contactFormQuery();
  const { formSuccessMessage } = formQueryData.strapiGlobal.contactFormMessage;
  const { formErrorMessage } = formQueryData.strapiGlobal.contactFormMessage;
  const URL: string = formQueryData.site.siteMetadata.contactFormUrl;

  const formSubmission = (formdata: any) => {
    if (isValid) {
      isloading(true);
      axios
        .post(URL, {
          formState: formdata,
        })
        .then(response => {
          if (response) {
            isloading(false);
            changeFormValidation(formSuccessMessage);
          }
        })
        .catch(error => {
          if (!error.response?.data?.body?.comment) {
            changeFormValidation(formErrorMessage);
          } else {
            changeFormValidation(
              error.response.data.body.comment.replace(/\*/g, '')
            );
          }
          isloading(false);
        });
      setTimeout(() => {
        changeFormValidation('');
      }, 15000);
    }
  };

  const onSubmit = (values: any) => {
    formSubmission(values);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="contact-form-wrapper"
      id="contactForm"
    >
      <div className="contact-form-wrapper">
        <div className="form-field">
          {details.name.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.name.input}
                {...register(`${details.name.name}`, {
                  required: {
                    value: true,
                    message: 'Name is required',
                  },
                  pattern: {
                    value: /^[a-zA-Z ]+$/g,
                    message: 'Invalid Name. Avoid Special characters',
                  },
                })}
                className="text-field"
              />

              {details.name.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.name.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              placeholder={details.name.input}
              {...register(`${details.name.name}`)}
            />
          )}
        </div>
        <div className="form-field">
          {details.email.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.email.input}
                {...register(`${details.email.name}`, {
                  required: {
                    value: true,
                    message: 'Email is required',
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/g,
                    message: 'Invalid Email',
                  },
                })}
                className="text-field"
              />
              {details.email.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.email.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              placeholder={details.email.input}
              {...register(`${details.email.name}`)}
            />
          )}
        </div>
        <div className="form-field">
          {details.phone.required === true ? (
            <>
              <input
                type="text"
                placeholder={details.phone.input}
                {...register(`${details.phone.name}`, {
                  required: {
                    value: true,
                    message: 'Number is required',
                  },
                  pattern: {
                    value: /^[0-9]{5,}$/g,
                    message: 'Please check the number',
                  },
                })}
                className="text-field"
              />
              {details.phone.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.phone.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="text-field"
              placeholder={details.phone.input}
              {...register(`${details.phone.name}`)}
            />
          )}
        </div>
        <div className="form-field">
          {details.message.required === true ? (
            <>
              <textarea
                cols={30}
                rows={20}
                className="text-field"
                placeholder={details.message.input}
                {...register(`${details.message.name}`, {
                  required: {
                    value: true,
                    message: 'Message is required',
                  },
                })}
              />
              {details.message.name && (
                <div className="error-message">
                  <ErrorMessage
                    errors={errors}
                    name={details.message.name}
                    render={({ message }) => <p>{message}</p>}
                  />
                </div>
              )}
            </>
          ) : (
            <textarea
              cols={30}
              rows={7}
              className="text-field"
              placeholder={details.message.input}
              {...register(`${details.message.name}`)}
            />
          )}
        </div>
        <div className="form-cta">
          <button type="submit" className="form-cta-button">
            {loading ? (
              <span className="btn-wrapper-loading">
                <span className="form-action-loader">
                  <Loader />
                </span>
              </span>
            ) : (
              <span className="btn-wrapper-inner">{submit.buttonText}</span>
            )}
          </button>
        </div>
        {formValidation && <p className="form-message">{formValidation}</p>}
      </div>
    </form>
  );
};

export { ContactForm };

const contactFormQuery = () => {
  return useStaticQuery(graphql`
    query contactFormQuery {
      strapiGlobal {
        contactFormMessage {
          formErrorMessage
          formSuccessMessage
        }
      }
      site {
        siteMetadata {
          contactFormUrl
        }
      }
    }
  `);
};
