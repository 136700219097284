import React from 'react';
import { SectionHeading } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ContentImageGalleryDataProps } from 'types';

const ContentImageGallery = ({
  title,
  imageLayout,
  description,
  gridImage,
}: ContentImageGalleryDataProps) => {
  return (
    <div className={`content_image_gallery  ${imageLayout}`}>
      <div className="container">
        <div className="content_image_gallery_wrapper">
          <div className="image_gallery_content">
            {' '}
            <SectionHeading title={title} description={description} />
          </div>
          <ul className="image_gallery">
            {gridImage?.length > 0 &&
              gridImage?.map((item: any) => {
                const { image } = item;
                const imageItem = image?.localFile
                  ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                  : null;

                return (
                  <li key={imageItem}>
                    <GatsbyImage
                      image={imageItem}
                      alt={image?.alternativeText ?? (title || '')}
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ContentImageGallery };
