import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header, Footer, ContactUs } from 'components';
import 'sass/style.scss';
import { FooterQuery } from 'queries/footer';

export interface LayoutProps {
  children: JSX.IntrinsicElements['div'];
  path: string;
}

const Layout: FC<LayoutProps> = ({ children, path }) => {
  const datas = FooterQuery();
  const footer = datas?.strapiGlobal?.footer;
  const footerLinks = datas?.strapiGlobal?.footerLinks;
  const connectToday = datas?.strapiGlobal?.connectUs;
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  if (path === '/contact-us/' || path === '/contact-us') {
    return (
      <>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

        <main>{children}</main>

        <Footer footer={footer} />
      </>
    );
  }
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <main>{children}</main>
      <ContactUs
        title={connectToday.title}
        ctaLink={connectToday.ctaLink}
        ctaText={connectToday.ctaText}
        description={connectToday.description.data.description}
      />
      <Footer footer={footer} footerLinks={footerLinks} />
    </>
  );
};

export default Layout;
