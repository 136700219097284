/* eslint-disable react/jsx-props-no-spreading */
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { MarkDown } from 'components';
import Slider from 'react-slick';
import { SliderDataContentProps, SliderDataProps } from 'types';

const HomeSlider = ({ sliderData }: SliderDataProps) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  const markdownTypesAllowed = ['text', 'strong', 'delete', 'emphasis', 'link'];
  return (
    <div className="swiper-container">
      <Slider {...settings}>
        {sliderData?.length > 0 &&
          sliderData.map((item: SliderDataContentProps) => {
            const { title, description, image } = item;
            const SliderImage =
              image && image.localFile
                ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                : null;
            return (
              <div className="swiper-container-wrapper" key={title}>
                {SliderImage && (
                  <div className="swiper-container-wrapper-image">
                    <GatsbyImage image={SliderImage} alt="" />
                  </div>
                )}

                <div className="swiper-container-wrapper-content">
                  {title && (
                    <h4 className="swiper-container-wrapper-content-title">
                      <MarkDown context="heading">{title}</MarkDown>
                    </h4>
                  )}
                  {description?.data?.description && (
                    <MarkDown className="swiper-container-wrapper-content-description">
                      {description.data.description}
                    </MarkDown>
                  )}
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export { HomeSlider };
