import { useStaticQuery, graphql } from 'gatsby';

const DefaultBannerQuery = () => {
  return useStaticQuery(graphql`
    query DefaultBannerQuery {
      strapiGlobal {
        defaultBanner {
          title
          ctaLink
          ctaText
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          mobileVideo {
            localFile {
              publicURL
            }
          }
          video {
            localFile {
              publicURL
            }
          }
          description {
            data {
              description
            }
          }
        }
      }
    }
  `);
};
export { DefaultBannerQuery };
