import { useStaticQuery, graphql } from 'gatsby';

const MenuQuery = () => {
  return useStaticQuery(graphql`
    query MenuQuery {
      strapiGlobal {
        header {
          title
          link
          active
          highlight
          desktop
        }
        mainNavLinks {
          active
          link
          title
        }
        socialMediaLink {
          facebookLink
          linkedInLink
          mailLink
        }
      }
    }
  `);
};
export { MenuQuery };
