import * as React from 'react';
import { Link } from 'gatsby';
import LinkedIn from 'assets/svg/linkedin.svg';
import Email from 'assets/svg/email.svg';
import FaceBook from 'assets/svg/facebook.svg';
import { FooterQuery } from 'queries/footer';
import { MenuQuery } from 'queries/menu';
import { NAV, FOOTER } from 'types';

export const Footer = ({ footer }: FOOTER) => {
  const data = footer;
  const links = FooterQuery();
  const menuItem = links.strapiGlobal.footerLinks;
  const menus = MenuQuery();
  const socialMediaLink = menus?.strapiGlobal?.socialMediaLink;
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_wrapper">
          <div className="footer-logo">
            <Link to="/">
              <img src="/madhuboats.svg" alt="madhuboats logo" />
            </Link>
          </div>
          <ul className="footer_nav">
            {menuItem?.length > 0 &&
              menuItem.map((menu: NAV) => (
                <li key={menu.link}>
                  {menu?.title && menu.link && (
                    <Link to={`/${menu.link}`}>{menu.title}</Link>
                  )}
                </li>
              ))}
          </ul>

          <ul className="social_media">
            <li>
              {socialMediaLink.linkedInLink && (
                <Link
                  href={socialMediaLink?.linkedInLink}
                  className="header-media-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedIn />
                </Link>
              )}
            </li>
            <li>
              {socialMediaLink.mailLink && (
                <Link
                  href={`mailto:${socialMediaLink?.mailLink}`}
                  rel="noreferrer"
                >
                  <Email />
                </Link>
              )}
            </li>
            <li>
              {socialMediaLink.facebookLink && (
                <Link
                  href={socialMediaLink?.facebookLink}
                  className="header-media-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaceBook />
                </Link>
              )}
            </li>
          </ul>
        </div>
        <div className="copyrights">{data?.copyrightmessage}</div>
      </div>
    </footer>
  );
};
