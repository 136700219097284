/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from 'react';
import {
  ImageListProps,
  ServiceListContentProps,
  ServiceListProps,
  ServicesProps,
} from 'types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ArrowDown from 'assets/svg/arrow-down.svg';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';

const Style = {
  height: 0,
} as React.CSSProperties;

const OurServicesMobile = ({ serviceList }: ServiceListContentProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };
  const linkRefs = useRef([]);

  const sliderRef = useRef(null);

  const handleTouchStart = () => {
    sliderRef?.current?.swiper?.autoplay?.stop();
  };

  const handleTouchEnd = () => {
    sliderRef?.current?.swiper?.autoplay?.start();
  };

  const addLinkRefs = (el: any) => {
    if (el && !linkRefs.current.includes(el)) {
      linkRefs.current.push(el);
    }
  };
  useEffect(() => {
    linkRefs.current.forEach((el: any) => {
      el.addEventListener('click', () => {
        sliderRef.current.swiper.slideTo(0);
      });
    });
    setTimeout(() => {
      const ourServicesItem = document.getElementsByClassName(
        'ourServices-mobile-wrapper-section'
      );
      const ourServicesTitle = document.getElementsByClassName(
        'ourServices-mobile-list-title'
      );

      const removeActiveClass = () => {
        for (let i = 0; i < ourServicesItem.length; i++) {
          ourServicesItem[i].classList.remove('active');
          const button = ourServicesItem[i].getElementsByTagName('button');
          if (button[0].classList.contains('buttonactive'))
            button[0].classList.remove('buttonactive');
          ourServicesItem[i].children[1].style.height = '0px';
        }
      };

      for (let i = 0; i < ourServicesTitle.length; i++) {
        ourServicesTitle[i].addEventListener('click', () => {
          const ourServicesItemActive =
            !ourServicesItem[i].classList.contains('active');
          removeActiveClass();
          if (ourServicesItemActive) {
            ourServicesItem[i].classList.toggle('active');
            const height =
              ourServicesItem[i].children[1].children[0].getBoundingClientRect()
                .height + 20;

            ourServicesItem[i].children[1].style.height = `${height}px`;
            const button = ourServicesItem[i].getElementsByTagName('button');
            button[0].classList.add('buttonactive');
          }
        });
      }

      const height =
        ourServicesItem[0].children[1].children[0].getBoundingClientRect()
          .height + 20;
      ourServicesItem[0].classList.toggle('active');
      ourServicesItem[0].children[1].style.height = `${height}px`;
    }, 1500);
  }, []);

  return (
    <div className="ourServices-mobile-wrapper">
      {serviceList &&
        serviceList.length > 0 &&
        serviceList.map((tab: ServiceListProps, index: number) => {
          {
            return tab.services.map((item: ServicesProps) => {
              return (
                <div className="ourServices-mobile-wrapper-section container">
                  <div className="ourServices-mobile-list-content">
                    <ul className="ourServices-mobile-list">
                      <li
                        className="ourServices-mobile-list-title"
                        key={item.title}
                      >
                        <button
                          type="button"
                          className={activeTab === index ? 'buttonactive' : ''}
                          onClick={() => handleTabClick(index)}
                          ref={addLinkRefs}
                        >
                          {item.title}
                          <ArrowDown />
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="ourServices-list-img"
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    style={Style}
                  >
                    <Swiper
                      ref={sliderRef}
                      slidesPerView={1}
                      centeredSlides
                      speed={800}
                      grabCursor
                      roundLengths
                      modules={[Autoplay, Pagination]}
                      pagination
                      autoplay={{
                        delay: 1500,
                        disableOnInteraction: true,
                        pauseOnMouseEnter: true,
                      }}
                    >
                      {serviceList &&
                        serviceList.length > 0 &&
                        serviceList[activeTab].services.map(
                          (result: ServicesProps) => {
                            return (
                              result.imageList &&
                              result.imageList.map((data: ImageListProps) => {
                                const Image: any =
                                  data.image && data.image.localFile
                                    ? getImage(
                                        data.image.localFile.childImageSharp
                                          ?.gatsbyImageData
                                      )
                                    : null;
                                return (
                                  <SwiperSlide key={data.title}>
                                    {Image && (
                                      <GatsbyImage
                                        image={Image}
                                        alt={data.title || ''}
                                      />
                                    )}
                                  </SwiperSlide>
                                );
                              })
                            );
                          }
                        )}
                    </Swiper>

                    <div className="ourServices-list-desc">
                      {item.description.data.description}
                    </div>
                  </div>
                </div>
              );
            });
          }
        })}
    </div>
  );
};

export { OurServicesMobile };
