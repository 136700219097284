import React, { FC, useEffect } from 'react';
import { Seo } from 'components';
import { useLocation } from '@reach/router';

export interface PageLayoutProps {
  children: JSX.IntrinsicElements['div'];
  title: string;
  className: string;
}

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  title,
  className,
}) => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <>
      <Seo title={title} />
      <div className={className}> {children}</div>
    </>
  );
};
