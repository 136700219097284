import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Menu } from 'components';
import { MenuQuery } from 'queries/menu';
import { MENU } from 'types';

const Header = () => {
  const {
    strapiGlobal: { header, mainNavLinks },
  }: MENU = MenuQuery();
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headerNavClass = isMenuOpen ? 'active' : '';
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolled(window.scrollY > 700);
    });
  }, []);

  useEffect(() => {
    document.body.classList.toggle('menu-open', isMenuOpen);
  }, [isMenuOpen]);
  return (
    <header
      className={`header  ${headerNavClass} ${scrolled ? 'scrolled' : ''}`}
    >
      <div className="header-wrapper">
        <div className="header-logo">
          <Link to="/">
            <img src="/madhuboats.svg" alt="madhuboats logo" className="logo" />
          </Link>
        </div>
        <div className="header-right">
          <Menu
            mainNav={header}
            mainNavLinks={mainNavLinks}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
        <button
          type="button"
          className="hamburger-menu close-btn"
          onClick={toggleMenu}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </header>
  );
};

export { Header };
