/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { GalleryModal, MoveUpSlide } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { GalleryItemProps, GalleryProps } from 'types';

const Gallery = ({ gallery }: GalleryProps) => {
  const [GalleryColumns, setsGalleryColumns]: Array<any> = useState([]);
  useEffect(() => {
    const galleryColumnCount = window.innerWidth < 576 ? 1 : 3;
    const gallaryColumnArray: Array<any> =
      galleryColumnCount === 3 ? [[], [], []] : [[]];
    gallery.forEach((element: GalleryItemProps, i: number) => {
      switch (i % galleryColumnCount) {
        case 0:
          gallaryColumnArray[0].push(element);
          break;
        case 1:
          gallaryColumnArray[1].push(element);
          break;
        case 2:
          gallaryColumnArray[2].push(element);
          break;
        default:
          gallaryColumnArray[0].push(element);
      }
    });
    setsGalleryColumns(gallaryColumnArray);
  }, [gallery]);
  const [isModal, setisModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleModalOpen = (item: GalleryItemProps) => {
    setisModal(true);
    setSelectedImage(gallery.indexOf(item));
    const slug = `${item.title.replace(/[^a-zA-Z\d:]/g, '-').toLowerCase()}-${
      item.strapi_id
    }`;
    window.history.pushState('', '', `/gallery/${slug}`);
  };
  const closeModal = () => {
    setisModal(false);
    setSelectedImage(null);
  };
  return (
    <>
      {GalleryColumns.map((GalleryColumn: [GalleryItemProps], i: number) => {
        return (
          <MoveUpSlide
            className="gallery-section-photo-column slide-up-down"
            GalleryItemsLength={gallery.length}
            paddingSpacing={i % 2 !== 0 && i !== 0 ? 17 : 22}
            key={`${GalleryColumn}${Math.random()}`}
          >
            {GalleryColumn.map((GalleryItem: GalleryItemProps) => {
              const { title, image } = GalleryItem;
              const imageItem = image?.localFile
                ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                : null;
              return (
                <div
                  className="gallery-section-photo-item"
                  onClick={() => {
                    handleModalOpen(GalleryItem);
                  }}
                  key={title}
                >
                  <GatsbyImage image={imageItem} alt={title} />
                  <div className="gallery-section-photo-item-content">
                    <p>{title}</p>
                  </div>
                </div>
              );
            })}
          </MoveUpSlide>
        );
      })}
      <GalleryModal
        isModal={isModal}
        closesModal={closeModal}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        gallery={gallery}
      />
    </>
  );
};

export { Gallery };
