import React from 'react';
import Moment from 'react-moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink } from 'components';
import { ArticleProps, ArticlesContentProps } from 'types';

const Bloglist = ({ articles }: ArticleProps) => {
  return (
    <div className="blogs-wrapper blog-list">
      <div className="blogs-wrapper-content">
        {articles &&
          articles.length > 0 &&
          articles.map((item: ArticlesContentProps) => {
            const { title, date, image, slug } = item;

            const Image: any =
              image && image && image.localFile
                ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                : null;
            return (
              <div className="blogs-wrapper-content-wrapper">
                <CTALink ctaLink={`/blogs/${slug}`}>
                  {Image && <GatsbyImage image={Image} alt={image[0]?.name} />}
                  {title && (
                    <p className="blogs-wrapper-content-title">{title}</p>
                  )}
                </CTALink>
                {date && (
                  <p className="blogs-wrapper-content-date">
                    <Moment format="D MMM, YYYY">{date}</Moment>
                  </p>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { Bloglist };
