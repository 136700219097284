import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

interface MoveUpSlideProps {
  children: any;
  className: string;
  GalleryItemsLength: any;
  paddingSpacing: number;
}

export const MoveUpSlide: FC<MoveUpSlideProps> = ({
  children,
  className,
  GalleryItemsLength,
  paddingSpacing,
}) => {
  const ref = useRef([]) as React.MutableRefObject<any[]>;
  ref.current = [];

  const addTorefs: any = (el: any) => {
    if (el && !ref.current.includes(el as never)) {
      ref.current.push(el as never);
    }
  };

  useEffect(() => {
    const element: any = ref.current;
    let animationColoumnHeight = 0;
    const galleryHeight = document.querySelector(
      '.gallery-section-photo'
    )!.clientHeight;
    ref.current[0].childNodes.forEach((el: any) => {
      animationColoumnHeight += el.clientHeight + paddingSpacing;
    });
    const animateTo = galleryHeight - animationColoumnHeight;
    const AnimationStartPosition =
      window.innerWidth > 992 ? `top-=30` : `top+=20`;
    if (window.innerWidth > 576) {
      if (GalleryItemsLength >= 6) {
        gsap.fromTo(
          element,
          { y: 0 },
          {
            duration: 0.3,
            y: animateTo - 55,
            ease: 'Power4.ease-in',
            scrollTrigger: {
              trigger: element,
              start: `${AnimationStartPosition} top`,
              end: 'bottom bottom',
              scrub: true,
              toggleActions: 'play none none reverse',
            },
          }
        );
      }
    }
  }, [ref.current]);

  return (
    <div ref={addTorefs} className={className}>
      {children}
    </div>
  );
};
