import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Moment from 'react-moment';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { CTALink } from 'components';
import { ArticleProps, ArticlesContentProps } from 'types';
import { truncate } from 'utils';

const Blogs = ({ articles, theme }: ArticleProps) => {
  return (
    <div className={`blogs-wrapper ${theme ? `theme-${theme}` : ''}`}>
      <div className="blogs-wrapper-content">
        <Swiper
          slidesPerView={1.2}
          breakpoints={{
            992: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {articles &&
            articles.length > 0 &&
            articles.map((item: ArticlesContentProps) => {
              const { title, date, image, slug } = item;
              const trimmedDescription =
                title?.length > 0 ? truncate(title, 35) : '';
              const Image: any =
                image && image && image.localFile
                  ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                  : null;
              return (
                <SwiperSlide key={title}>
                  <div className="blogs-wrapper-content-wrapper">
                    <CTALink ctaLink={`/blogs/${slug}`}>
                      {Image && (
                        <GatsbyImage
                          image={Image}
                          alt={`${image?.name} | '' `}
                        />
                      )}
                      {trimmedDescription && (
                        <p className="blogs-wrapper-content-title">
                          {trimmedDescription}
                        </p>
                      )}
                    </CTALink>
                    {date && (
                      <p className="blogs-wrapper-content-date">
                        <Moment format="D MMM, YYYY">{date}</Moment>
                      </p>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export { Blogs };
