import { useStaticQuery, graphql } from 'gatsby';

const FooterQuery = () => {
  return useStaticQuery(graphql`
    query FooterQuery {
      strapiGlobal {
        id
        footer {
          copyrightmessage
        }
        footerLinks {
          active
          link
          title
          highlight
    }
        connectUs {
          title
          description {
            data {
              description
            }
          }
          ctaText
          ctaLink
        }
      }
    }
  `);
};
export { FooterQuery };
