import React, { FC } from 'react';
import { Link } from 'gatsby';
import { MarkDown } from 'components';
import BannerArrow from 'assets/svg/arrowright.svg';
import { ConnectUsProps } from 'types';

export const ContactUs: FC<ConnectUsProps> = ({
  title,
  description,
  ctaText,
  ctaLink,
}: any) => {
  return (
    <div className="contactus theme-light">
      <div className="container">
        {title && (
          <h2 className="section-title">
            <MarkDown context="heading">{title}</MarkDown>
          </h2>
        )}
        {description && (
          <MarkDown className="section-content-center">{description}</MarkDown>
        )}
        {ctaText && ctaLink && (
          <div className="cta-btn">
            <Link to={ctaLink}>
              <span>{ctaText}</span>
              <BannerArrow />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
