import React, { useEffect, useState, FC } from 'react';
import BannerArrow from 'assets/svg/arrowright.svg';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MarkDown } from 'components';
import { HeroBannerProps, videoProps } from 'types';

const getCurrentDimension = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

const getVideoURL = (
  width: number,
  video?: videoProps,
  mobileVideo?: videoProps
) => {
  if (width < 800 && mobileVideo?.localFile?.publicURL) {
    return mobileVideo.localFile.publicURL;
  }
  if (video?.localFile?.publicURL) {
    return video.localFile.publicURL;
  }
  return '';
};
const HeroBanner: FC<HeroBannerProps> = ({
  title,
  description,
  ctaLink,
  ctaText,
  image,
  video,
  mobileVideo,
}: any) => {
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  const [videoURL, setVideoURL] = useState('');
  useEffect(() => {
    setScreenSize(getCurrentDimension());
  }, []);
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);
    setVideoURL(getVideoURL(screenSize.width, video, mobileVideo));
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);
  const BannerImage = image?.localFile?.childImageSharp?.gatsbyImageData
    ? getImage(image?.localFile?.childImageSharp?.gatsbyImageData)
    : null;
  return (
    <div className="hero-banner-wrapper">
      {videoURL && videoURL !== '' ? (
        <div className="hero-banner-media-wrapper">
          <video loop autoPlay muted playsInline>
            <source src={videoURL} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="hero-banner-media-wrapper">
          <GatsbyImage image={BannerImage} alt="" />
        </div>
      )}
      <div className="hero-banner-container container">
        <div className="hero-banner-container-content">
          {title && <h2 className="hero-banner-title">{title}</h2>}
          {description && (
            <MarkDown className="hero-banner-description">
              {description}
            </MarkDown>
          )}
          {ctaText && (
            <div className="hero-banner-cta-wrapper">
              <Link className="hero-banner-ctalink" to={ctaLink}>
                <span>{ctaText}</span>
                <BannerArrow />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { HeroBanner };
