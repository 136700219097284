import { MarkDown } from 'components';
import React from 'react';
import { DescriptionProps } from 'types';

const Description = ({ description }: DescriptionProps) => {
  return (
    <div className="container content-description">
      <MarkDown>{description}</MarkDown>
    </div>
  );
};

export { Description };
