import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { NAV, MENU_PROP } from 'types';
import { MenuQuery } from 'queries/menu';
import LinkedIn from 'assets/svg/linkedin.svg';
import Email from 'assets/svg/email.svg';
import FaceBook from 'assets/svg/facebook.svg';

const Menu = ({ mainNav, setIsMenuOpen }: MENU_PROP) => {
  const menuItemHandler = () => {
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    setIsMenuOpen(false);
  }, []);
  const data = MenuQuery();
  const socialMediaLink = data?.strapiGlobal?.socialMediaLink;
  return (
    <nav className="menu-wrapper">
      <ul className="header-nav menu">
        {mainNav?.length > 0 &&
          mainNav.map((menu: NAV) => {
            return (
              <React.Fragment key={menu.link}>
                {menu.desktop && (
                  <li className={menu.highlight === true ? 'highlight' : ''}>
                    {menu?.title && menu.link && (
                      <Link to={`/${menu.link}`} onClick={menuItemHandler}>
                        {menu.title}
                      </Link>
                    )}
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </ul>
      <ul className="menu-list menu">
        {mainNav?.length > 0 &&
          mainNav.map((menu: NAV) => {
            return (
              <React.Fragment key={menu.link}>
                {menu.desktop !== true && (
                  <li className={menu.highlight === true ? 'highlight' : ''}>
                    {menu?.title && menu.link && (
                      <Link to={`/${menu.link}`} onClick={menuItemHandler}>
                        {menu.title}
                      </Link>
                    )}
                  </li>
                )}
              </React.Fragment>
            );
          })}
      </ul>

      <div className="header-list-social-media">
        {socialMediaLink.facebookLink && (
          <Link
            to={socialMediaLink?.facebookLink}
            className="header-media-link"
            target="_blank"
            onClick={menuItemHandler}
            rel="noreferrer"
          >
            <FaceBook />
          </Link>
        )}
        {socialMediaLink.linkedInLink && (
          <Link
            to={socialMediaLink?.linkedInLink}
            className="header-media-link"
            target="_blank"
            onClick={menuItemHandler}
            rel="noreferrer"
          >
            <LinkedIn />
          </Link>
        )}
        {socialMediaLink.mailLink && (
          <Link to={`mailto:${socialMediaLink?.mailLink}`} rel="noreferrer">
            <Email />
          </Link>
        )}
      </div>
    </nav>
  );
};
export { Menu };
