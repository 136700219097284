/* eslint-disable no-unused-expressions */
import React, { FC } from 'react';
import {
  CardSection,
  TextImage,
  ProfileDetail,
  TwoImageSection,
  HeroBanner,
  ContentImageGallery,
  HomeSlider,
  ProjectSection,
  ContactPeople,
  ContactUs,
  OurServices,
  OurServicesMobile,
  SectionHeading,
  Blogs,
  Description,
  Gallery,
  Bloglist,
} from 'components';
import { DefaultBannerQuery } from 'queries/defaultbanner';
import { useLocation } from '@reach/router';

export const AllDynamicComponents: FC = ({ alldynamicComponents }: any) => {
  const location = useLocation();
  const defaultBannerData = DefaultBannerQuery();
  const { title, description, image, ctaLink, ctaText } =
    defaultBannerData.strapiGlobal.defaultBanner;
  const defaultbanner = alldynamicComponents?.some(
    (component: any) =>
      component.internal?.type === 'STRAPI__COMPONENT_DYNAMIC_BANNER' ||
      component.internal?.type === 'STRAPI__DEFAULT_BANNER' ||
      component.internal?.type === 'STRAPI__HOMEPAGE_BANNER'
  );
  if (!defaultbanner)
    alldynamicComponents?.unshift({
      internal: { type: 'STRAPI__DEFAULT_BANNER' },
    });
  return (
    <div>
      {alldynamicComponents?.map((section: any) => {
        return (
          <React.Fragment key={section.id}>
            {section.internal?.type === 'STRAPI__COMPONENT_DYNAMIC_BANNER' && (
              <HeroBanner
                title={section.title}
                ctaLink={section.ctaLink}
                ctaText={section.ctaText}
                description={section.description.data.description}
                image={section.image}
              />
            )}
            {section.internal?.type === 'STRAPI__DEFAULT_BANNER' && (
              <HeroBanner
                title={title}
                ctaLink={ctaLink}
                ctaText={ctaText}
                description={description}
                image={image}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_CONTENT_LIST' && (
              <CardSection
                cardSection={section.cardSection}
                sectionTitle={section.workTitleAndDescription}
                theme={section.theme}
                backgroundImage={section.backgroundImage}
                summary={section.summary?.description.data.description}
                ctaLink={section.ctaLink}
                ctaText={section.ctaText}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_TWO_COLUMN' && (
              <TextImage textImage={section.twoColumnList} />
            )}

            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_IMAGESUMMARY_SLIDER' && (
              <HomeSlider sliderData={section.slider} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_PROFILE_INFO' && (
              <ProfileDetail
                name={section.name}
                designation={section.designation}
                description={section.description.data.description}
                image={section.image}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_TWO_IMAGESECTION' && (
              <TwoImageSection
                description={section.description}
                twoColumnImage={section.twoColumnImage}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_TITLE_DESCRIPTION_IMAGE_LAYOUT' && (
              <ContentImageGallery
                title={section.title}
                imageLayout={section.imageLayout}
                description={section.description.data.description}
                gridImage={section.gridImage}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_OUR_PROJECT_LIST' && (
              <ProjectSection projectList={section.projectList} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_CONTACT_PEOPLE_DETAIL' && (
              <ContactPeople contactPeople={section.contactPeople} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_CONNECT_WITH_US' && (
              <ContactUs
                title={section.title}
                ctaLink={section.ctaLink}
                ctaText={section.ctaText}
                description={section.description.data.description}
              />
            )}

            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_TITLEAND_DESCRIPTION' && (
              <SectionHeading
                title={section.title}
                description={section.description.data.description}
                align={section.align}
              />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_SHARED_DESCRIPTION' && (
              <Description description={section.description.data.description} />
            )}

            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_OUR_SERVICE_LIST' && (
              <OurServices serviceList={section.serviceList} />
            )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_OUR_SERVICE_LIST' && (
              <OurServicesMobile serviceList={section.serviceList} />
            )}
            {section.internal?.type === 'STRAPI__COMPONENT_SHARED_ARTICLE' &&
              !location.pathname.includes('/blogs') && (
                <Blogs articles={section.articles} theme={section.theme} />
              )}
            {section.internal?.type === 'STRAPI__COMPONENT_SHARED_ARTICLE' &&
              location.pathname.includes('/blogs') && (
                <Bloglist articles={section.articles} theme={section.theme}/>
              )}
            {section.internal?.type ===
              'STRAPI__COMPONENT_DYNAMIC_PHOTO_GALLERY' && (
              <div className="gallery-section-photo container">
                <Gallery gallery={section.gallery} />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
