/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  ImageListProps,
  ServiceListContentProps,
  ServiceListProps,
  ServicesProps,
} from 'types';
import { Pagination, Autoplay } from 'swiper/modules';

const OurServices = ({ serviceList }: ServiceListContentProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const linkRefs = useRef([]);
  const sliderRef = useRef(null);

  const handleMouseEnter = () => {
    sliderRef?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    sliderRef?.current?.swiper?.autoplay?.start();
  };

  const addLinkRefs = el => {
    if (el && !linkRefs.current.includes(el)) {
      linkRefs.current.push(el);
    }
  };
  useEffect(() => {
    linkRefs.current.forEach((el: any) => {
      el.addEventListener('click', () => {
        sliderRef.current.swiper.slideTo(0);
      });
    });
  }, []);

  return (
    <div className="container ourServices-wrapper">
      <div className="ourServices-list-content">
        <ul className="ourServices-list">
          {serviceList &&
            serviceList.length > 0 &&
            serviceList.map((tab: ServiceListProps, index: number) => {
              {
                return tab.services.map((item: ServicesProps) => {
                  return (
                    <li key={item.title}>
                      <button
                        type="button"
                        className={activeTab === index ? 'active' : ''}
                        onClick={() => handleTabClick(index)}
                        ref={addLinkRefs}
                      >
                        {item.title}
                      </button>
                    </li>
                  );
                });
              }
            })}
        </ul>
      </div>
      <div className="ourServices-list-img_section">
        <div
          className="ourServices-list-img"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Swiper
            ref={sliderRef}
            slidesPerView={1}
            centeredSlides
            speed={800}
            grabCursor
            roundLengths
            modules={[Autoplay, Pagination]}
            pagination
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
          >
            {serviceList &&
              serviceList.length > 0 &&
              serviceList[activeTab].services.map((item: ServicesProps) => {
                return (
                  item.imageList &&
                  item.imageList.map((data: ImageListProps) => {
                    const Image: any =
                      data.image && data.image.localFile
                        ? getImage(
                            data.image.localFile.childImageSharp
                              ?.gatsbyImageData
                          )
                        : null;
                    return (
                      <React.Fragment>
                        <SwiperSlide key={data.title}>
                          {Image && (
                            <GatsbyImage image={Image} alt={data.title || ''} />
                          )}
                        </SwiperSlide>
                      </React.Fragment>
                    );
                  })
                );
              })}
          </Swiper>
        </div>
        <div className="ourServices-content">
          {serviceList &&
            serviceList.length > 0 &&
            serviceList[activeTab].services.map((item: ServicesProps) => {
              return (
                <>
                  <div className="ourServices-list-desc">
                    {item.description.data.description}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export { OurServices };
