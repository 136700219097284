import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { TwoImageProps, TwoImageSectionProps } from 'types';
import { MarkDown } from 'components';

export const TwoImageSection = ({
  description,
  twoColumnImage,
}: TwoImageSectionProps) => {
  return (
    <div className="container">
      <div className="twoimage-section">
        {description && (
          <MarkDown className="twoimage-section-desc">
            {description.data.description}
          </MarkDown>
        )}
        <div className="twoimage-section-column">
          {twoColumnImage?.length > 0 &&
            twoColumnImage.map((item: TwoImageProps) => {
              const { title, image } = item;
              const twoImage = image?.localFile
                ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
                : null;
              return (
                <div className="twoimage-section-wrapper" key={title}>
                  {twoImage && (
                    <div className="twoimage-section-img">
                      <GatsbyImage image={twoImage} alt="" />
                    </div>
                  )}
                  {title && <h4 className="twoimage-section-title">{title}</h4>}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
