import React, { FC } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ImagePropTypes } from 'types';

interface ProfileDetailProps {
  name: String;
  description: String;
  designation: String;
  image: ImagePropTypes;
}
export const ProfileDetail: FC<ProfileDetailProps> = ({
  name,
  description,
  designation,
  image,
}) => {
  const ProfileImage =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;

  return (
    <div className="container">
      <div className="profile-detail">
        <div className="profile-detail-wrapper">
          {ProfileImage && (
            <div className="profile-detail-img">
              <GatsbyImage image={ProfileImage} alt="" />
            </div>
          )}

          <div className="profile-detail-section">
            {name && (
              <span className="profile-detail-section-name">{name}</span>
            )}
            {designation && (
              <span className="profile-detail-section-designation">
                {designation}
              </span>
            )}
          </div>
        </div>
        {description && (
          <div className="profile-detail-desc">{description}</div>
        )}
      </div>
    </div>
  );
};
