import React, { FC } from 'react';
import { MarkDown } from 'components';

interface SectionHeadingProps {
  title: string;
  description: string;
  align?: string;
}
export const SectionHeading: FC<SectionHeadingProps> = ({
  title,
  description,
  align,
}) => {
  return (
    <section className="section">
      <div className={`container ${align}`}>
        {title && (
          <h2 className="section-title">
            <MarkDown context="heading">{title}</MarkDown>
          </h2>
        )}
        {description && (
          <MarkDown className="section-content">{description}</MarkDown>
        )}
      </div>
    </section>
  );
};
